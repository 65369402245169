import { Dropdown, Table, Text } from 'components/basic';
import ListLayout from 'components/layouts/ContentLayout/ListLayout';
import React, { useCallback, useState } from 'react';
import { Button, Tooltip } from 'antd/lib';
import { getFareConfigAuditLogs } from 'services/message.service';
import useApi from 'hooks/useApi';
import { Link } from 'react-router-dom';
import useFilter from 'hooks/useFilter';
import useMount from 'hooks/useMount';
import useModal from 'hooks/useModal';
import { DownOutlined } from '@ant-design/icons';
import { convertTimestampToDateTime } from 'services/date.service';
import moment from 'moment';
import MarkdownRenderer from 'components/basic/MarkdownRenderer/MarkdownRenderer';
import Modal from 'antd/lib/modal/Modal';
import FinanceAuditLogsFilter from './FinanceAuditLogsFilter';

const FareConfigAuditLogsPage = () => {
    const viewDetailModal = useModal();
    const [data, setData] = useState([]);
    // const [pagination, setPagination]: any = useState({
    //     defaultCurrent: 1,
    //     defaultPageSize: 10,
    // });

    const { modifyFilters, requestState } = useFilter({
        page_size: 10,
        page: 1,
        search_by: '',
        search_key: '',
        sort_key: 'first_name',
        sort_by: 'asc',
        operator_id: '',
        data_type: '',
        data_change: '',
    });

    const { request, loading } = useApi({
        api: getFareConfigAuditLogs,
    });

    const ColumnActions = (column: any) => {
        const { column: auditLog } = column || {};
        const { changed_by, changes, name } = auditLog || {};
        return (
            <Dropdown
                menu={{
                    items: [
                        {
                            label: 'View Details',
                            key: '0',
                            onClick: () => {
                                viewDetailModal.show({
                                    title: 'Log Details',
                                    className: 'w-full',
                                    width: 800,
                                    children: (
                                        <div>
                                            <p>{`${changed_by.name} performed ${name.split('.')[1].toUpperCase()}`}</p>
                                            <span className="font-semibold">Troubleshooting:</span>
                                            <pre className="text-gray bg-slate-50 rounded p-2">{changes}</pre>
                                        </div>
                                    ),
                                });
                            },
                        },
                    ],
                }}
            >
                <Button>
                    Actions
                    <DownOutlined />
                </Button>
            </Dropdown>
        );
    };

    const columns = [
        {
            title: 'Log ID',
            render: (user: any) => (
                <Text type="uuid" className="w-56">
                    {user.id}
                </Text>
            ),
        },
        // {
        //     title: 'Description',
        //     key: 'description',
        //     render: (user: any) => <MarkdownRenderer markdownContent={user.description || 'No Description'} />,
        // },
        {
            title: 'Operator',
            key: 'operator',
            render: (user: any) => (
                <div className="flex items-center">
                    <Text>{`${user.changed_by.name}`}</Text>
                </div>
            ),
        },
        {
            title: 'Action',
            render: (user: any) => {
                const actionColors: Record<string, string> = {
                    DELETE: 'text-red-500',
                    CREATE: 'text-green-500',
                    UPDATE: 'text-orange-500',
                    READ: 'text-cyan-500',
                    VIEW_CUSTOMERS: 'text-blue-500',
                    VIEW_CUSTOMER: 'text-blue-500',
                    VIEW_LIST: 'text-orange-500',
                    VIEW: 'text-orange-600',
                };
                const action = user.name.split('.')[1].toUpperCase();
                const color = actionColors[action] || 'text-gray-400';
                return <Text color={color}>{action || 'No Action'}</Text>;
            },
        },
        {
            title: 'Changes',
            key: 'changes',
            render: (user: any) => {
                return <pre>{user.changes}</pre>;
            },
        },
        {
            title: 'Timestamp',
            key: 'timestamp',
            render: (user: any) => (
                <Tooltip title={convertTimestampToDateTime(user.created_at)}>
                    <span color={user.timestamp ? 'text-black' : 'text-gray-400'}>
                        {moment.utc(user.created_at).add(8, 'hour').format('MMMM DD, YYYY hh:mm:ss A') ||
                            'No Timestamp'}
                    </span>
                </Tooltip>
            ),
        },

        {
            title: '',
            render: (column: any) => <ColumnActions column={column} />,
        },
    ];

    const fetchList = useCallback(
        async (requestState: {}) => {
            try {
                const result = await request(requestState);
                let d = result.data;
                console.log(result);
                if (d) {
                    setData(d);
                }
                // if (d.status == 'success') {
                //     let logs = d.data.audit_logs.map((c: any) => {
                //         return {
                //             ...c,
                //             key: c.id,
                //         };
                //     });
                //     setData(logs);
                //     console.log('logs', logs);
                //     let pagination = d.data.pagination;
                //     let p = {
                //         current: pagination.page,
                //         defaultCurrent: 20,
                //         total: pagination.total_count,
                //         pageSize: pagination.page_size,
                //     };
                //     setPagination(p);
                // } else {
                //     console.log('error');
                //     // TODO: error screen
                // }
            } catch (error) {
                throw error;
            }
        },
        [request],
    );

    useMount(() => {
        fetchList(requestState);
        console.log('fetched');
    });

    const onTableChange = useCallback(
        async (pagination: any, filters: any, sorter: any) => {
            const { current, pageSize } = pagination;
            const { field, order } = sorter;
            const { requestState } = await modifyFilters({
                page_size: pageSize,
                page: current,
                sort_key: field,
                sort_by: order == 'ascend' ? 'asc' : 'desc',
                operator_id: filters.operatorId ? filters.operatorId : '',
            });
            await fetchList(requestState || {});
        },
        [fetchList, modifyFilters],
    );

    const handleOnShowModal = useCallback(() => {
        viewDetailModal.show();
    }, [viewDetailModal]);

    const onFilterSubmit = useCallback(
        async (filter: any) => {
            const { requestState } = await modifyFilters({
                page: 1,
                operator_id: filter.operatorId,
                data_type: filter.dataType,
                data_change: filter.dataChange,
            });
            await fetchList(requestState || {});
        },
        [fetchList, modifyFilters],
    );

    return (
        <div className="flex flex-col gap-2">
            <div className="flex">
                <Link className="text-gray-500 text-sm cursor-pointer hover:text-blue" to={'/logs'}>
                    ← Back to Logs
                </Link>
            </div>
            <ListLayout
                title="Fare Config Audit Logs"
                // searchComponent={<FinanceAuditLogsFilter onFilterSubmit={onFilterSubmit} />}
            >
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={data}
                    // pagination={{
                    //     ...pagination,
                    //     showSizeChanger: true,
                    //     showQuickJumper: true,
                    //     showTotal: (total: any) => `${total?.toLocaleString()} items`,
                    // }}
                    // onChange={onTableChange}
                    pagination={{
                        total: data.length,
                    }}
                />
                <Modal {...viewDetailModal} onOk={() => viewDetailModal.close()} />
            </ListLayout>
        </div>
    );
};

export default FareConfigAuditLogsPage;
