// api.ts (or wherever you create the axios instance)
import axios, { AxiosRequestConfig } from "axios";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;
const appVersion = process.env.REACT_APP_CLIENT_APP_VERSION || "web-1.5.0";
const appName = process.env.REACT_APP_CLIENT_APP_NAME || "AAP";

const api = axios.create({
  baseURL: apiServerUrl,
});

const setAuthInterceptor = (accessToken: string) => {
  api.interceptors.request.use((config: any) => {
    if (accessToken) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${accessToken}`,
      };
    }

    config.headers = {
      ...config.headers,
      "App-Type": getBrowserType(),
      "App-Version": appVersion,
      "App-Name": appName,
    };

    return config;
  });
};

function getBrowserType(): string {
  const userAgent = navigator.userAgent;

  if (userAgent.includes("Firefox")) {
    return "Firefox";
  } else if (userAgent.includes("Edg")) {
    return "Microsoft Edge";
  } else if (userAgent.includes("Chrome") && !userAgent.includes("Edg")) {
    return "Chrome";
  } else if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
    return "Safari";
  } else if (userAgent.includes("Opera") || userAgent.includes("OPR")) {
    return "Opera";
  } else if (userAgent.includes("Trident") || userAgent.includes("MSIE")) {
    return "Internet Explorer";
  }

  return "Unknown";
}


export { api, setAuthInterceptor };
